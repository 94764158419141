import * as React from "react"
import { Router } from '@reach/router'
import SoraLayout from "../components/AppLayout"
import Auth from './auth'
import Dashboard from "./index"


const App = () => {
  return (
      <SoraLayout >
        <Router basepath="/">
          <Auth path="/auth" />
          <Dashboard path="/" />
        </Router>
      </SoraLayout>
  )
}


export default App;
